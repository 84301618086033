<template>
  <v-app>
    <v-row id="shipping-pickup">
      <v-col lg="4" md="12" sm="12">
        <h1>Shipping & Pickup</h1>
      </v-col>
      <v-col cols="12" class="shipping-pickup" v-if="methods.length>0">
        <v-row>
          <v-col lg="4" md="12" sm="12">
            <br />
            <h5>Current shipping methods</h5>
            <br />
            <p>These are the shipping methods that you have added to your store. When enabled, they are available for customers to choose from at checkout.</p>
          </v-col>
          <v-col lg="8" md="12" sm="12">
            <v-card class="mt-3" v-for="(method, i) of methods" :key="i">
              <v-card-title class="card-title">
                {{method.title}}
                <p class="mt-4 ml-2">[{{method.type.toUpperCase()}}]</p>
                <div class="ml-auto">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-row class="mr-4">
                        <span>
                          <v-switch
                            color="green"
                            v-model="method.is_active"
                            @click="toggleStatus(method.id)"
                          ></v-switch>
                        </span>
                        <span class="ml-3" v-bind="attrs" v-on="on">
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </v-row>
                    </template>
                    <v-list>
                      <v-list-item @click="updateShippingMethod(method.id,method.type)">
                        <v-list-item-title>
                          <i class="fa fa-edit mr-2"></i> Edit
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteShippingMethod(method.id)">
                        <v-list-item-title>
                          <i class="fa fa-trash mr-2"></i> Delete
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-card-title>
              <v-card-text>
                <v-row class="ml-auto">
                  <h4 v-if="method.price">| Price: {{currency_symbol}}{{method.price}}</h4>
                  <h4
                    v-if="method.minimum_order_subtotal"
                  >| Minimum Order: {{currency_symbol}}{{method.minimum_order_subtotal}}</h4>
                </v-row>
                <p>{{method.description}}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="shipping-pickup">
        <v-row>
          <v-col lg="4" md="12" sm="12">
            <h5>Add a new shipping method</h5>
            <br />
            <p>Select how you’ll be getting your products to customers. Add local delivery, curbside and drive-through pickup, or enable shipping with the most popular carriers.</p>
            <v-btn class="mt-3" @click="manageShippingZone" outlined dense>Manage Shipping Zones</v-btn>
          </v-col>
          <v-col lg="8" md="12" sm="12">
            <v-card>
              <v-card-title class="card-title">Shipping and Delivery</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col lg="8" md="6" sm="12">
                    <p>Add a simple flat rate shipping, set up custom rates, or use automatic rates from carriers like UPS, USPS, FedEx, Royal Mail, Canada Post, and Australia Post.</p>
                    <br />
                    <v-btn @click="newShippingMethod('shipping-method')" outlined dense>
                      <i class="fa fa-plus mr-3"></i> Add Shipping
                    </v-btn>
                  </v-col>
                  <v-col lg="4" md="6" sm="12">
                    <div class="shipping-card-vertical"></div>
                    <div class="ml-3">
                      <ul class="shipping-list">
                        <li
                          class="shipping-list-item"
                        >Custom rates for Nepal Post and other carriers</li>
                        <li class="shipping-list-item">Weight-based and subtotal-based rates</li>
                        <li class="shipping-list-item">Free shipping promotions</li>
                        <li class="shipping-list-item">Local delivery</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mt-5">
              <v-card-title class="card-title">Self Pickup</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col lg="8" md="6" sm="12">
                    <p>Add a curbside, drive-through, or in-store pickup. Specify your business hours, the pickup location, and add a brief instruction on how the pickup works for customers.</p>
                    <br />
                    <v-btn @click="newShippingMethod('shipping-method-type-pickup')" outlined dense>
                      <i class="fa fa-plus mr-3"></i> Add Pickup
                    </v-btn>
                  </v-col>
                  <v-col lg="4" md="6" sm="12">
                    <div class="shipping-card-vertical"></div>
                    <div class="ml-3">
                      <ul class="shipping-list">
                        <li class="shipping-list-item">In-store pickup</li>
                        <li class="shipping-list-item">Curbside pickup</li>
                        <li class="shipping-list-item">Drive-through pickup</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import EcommerceShippingMethodService from "@/services/store/shipping/method/EcommerceShippingMethodService";
const methodService = new EcommerceShippingMethodService();

export default {
  data() {
    return {
      domainname: null,
      methods: []
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currency_symbol() {
      return this.currentUser.store_config.currency_symbol;
    }
  },
  mounted() {
    this.domainname = this.$route.params.domainname;
    this.getShippingMethod();
  },
  methods: {
    getShippingMethod() {
      methodService.paginate(this.domainname).then(response => {
        this.methods = response.data.data;
      });
    },
    deleteShippingMethod(id) {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        methodService.delete(this.domainname, id).then(response => {
                            this.$snotify.success("Deleted Successfully");
                            this.getShippingMethod();
                        });
                    }
                }
            }
        )

    },
    toggleStatus(id) {
      methodService.toggleStatus(this.domainname, id).then(response => {
        this.$snotify.success("Updated Successfully");
        this.getShippingMethod();
      });
    },
    updateShippingMethod(id, type) {
      let routeName = null;
      switch (type) {
        case "pickup":
          routeName = "shipping-method-type-pickup-update";
          break;
        case "free":
          routeName = "shipping-method-type-free-update";
          break;
        case "custom":
          routeName = "shipping-method-type-custom-update";
          break;
        case "conditional":
          routeName = "shipping-method-type-conditional-update";
          break;
          case "flat":
          routeName = "shipping-method-type-flat-update";
          break;
      }
      this.$router.push({
        name: routeName,
        params: { id: id }
      });
    },
    newShippingMethod(name) {
      this.$router.push({
        name: name
      });
    },
    manageShippingZone() {
      this.$router.push({ name: "shipping-zone" });
    }
  }
};
</script>
<style scoped>
.shipping-pickup h5 {
  font-size: 40px;
  font-weight: 700;
}
.shipping-pickup h5 {
  font-size: 20px;
  font-weight: 600;
}
.shipping-pickup .card-title {
  font-weight: 700;
}
.shipping-pickup p {
  font-size: 12px !important;
  font-weight: 500;
}
.shipping-card-vertical::before {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 0;
  border-left: 1px solid #cdd4db;
}
.shipping-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 22px;
}
.shipping-list-item {
  margin: 0 0 5px 0;
}
</style>
